import { createChatBotMessage } from 'react-chatbot-kit';
import BotAvatar from './chatbotComponents/BotAvatar';
import Options from './chatbotComponents/Options';

const config = {
  initialMessages: [
    createChatBotMessage(
      'Bonjour et bienvenue sur notre service client ! Je suis là pour vous aider à passer votre réclamation. Comment puis-je vous assister aujourd"hui ?',
      {
        widget: 'options'
      }
    )
  ],
  botName: 'CliClaim Bot',
  customComponents: { botAvatar: (props) => <BotAvatar {...props} /> },
  widgets: [
    {
      widgetName: 'options',
      widgetFunc: (props) => <Options {...props} />
    }
  ]
};

export default config;
