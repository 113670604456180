import React from 'react';
import { useTranslation } from 'react-i18next';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const { t } = useTranslation();

  // Scénario: Passer une Réclamation
  const handleMakeAComplaint = () => {
    // Premier message : introduction
    const initialMessage = createChatBotMessage(
      t(
        "Je suis désolé d'apprendre que vous avez rencontré un problème. Pour passer une réclamation :"
      ),
      { withAvatar: true }
    );

    // Deuxième message : liste des étapes
    const stepsMessage = createChatBotMessage(
      t(`• Créer un compte sur ce lien ou à travers notre application mobile
         • Localiser l’établissement à réclamer
         • Justifier votre expérience avec une facture
         • Passer votre réclamation en mode anonyme ou visible
         • Accompagner votre réclamation avec des photos et un vocal`),
      { withAvatar: true }
    );

    // Mettre à jour l'état avec les deux messages
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, initialMessage, stepsMessage]
    }));
  };

  // Scénario: Suivre une Réclamation Existante
  const handleFollowUpComplaint = () => {
    const botMessage = createChatBotMessage(
      t(
        "Pour suivre votre réclamation, vous pouvez consulter l'historique de réclamation et échanger par messagerie instantanée avec le gestionnaire."
      )
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage]
    }));
  };

  // Scénario: Obtenir des Informations Générales
  const handleGeneralInfo = () => {
    const botMessage = createChatBotMessage(
      t(
        "Quel type d'informations cherchez-vous ? Délai de traitement, Confidentialité, ou contacter le service client."
      )
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage]
    }));
  };

  // Scénario: Parler à un Agent
  const handleSpeakToAgent = () => {
    const botMessage = createChatBotMessage(
      t(
        'Je vais vous mettre en relation avec un agent. En attendant, pouvez-vous décrire brièvement le problème ?'
      )
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage]
    }));
  };

  // Autre Scénario
  const handleOther = () => {
    const botMessage = createChatBotMessage(
      t(
        'Veuillez exprimer votre besoin et vous serez redirigé vers le service client.'
      )
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage]
    }));
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleMakeAComplaint,
            handleFollowUpComplaint,
            handleGeneralInfo,
            handleSpeakToAgent,
            handleOther
          }
        });
      })}
    </div>
  );
};

export default ActionProvider;
